<template>
	<div class="row mb-2">
		<div
			v-if="!loadingOverride"
			class="col">
			<div class="h5">
				{{ translate(paymentMethod.name) }}
			</div>
			<template v-if="billing.country_code !== '' && !loadingState">
				<div class="row mb-2">
					<div class="col-auto">
						<p class="mb-0">
							<b>{{ billing.first_name }} {{ billing.last_name }}</b>
						</p>
						<p class="mb-0">
							{{ billing.address }}
						</p>
						<p class="mb-0">
							{{ billing.address2 }}
						</p>
						<p class="mb-0">
							{{ billing.city }}, {{ billing.region_name }}, {{ billing.postcode }}
						</p>
						<p class="mb-0">
							{{ translate(billing.country_code.toLowerCase()) }}
						</p>
					</div>
				</div>
				<div
					v-if="CREDIT_CARD_FORM_METHODS.includes(paymentMethod.name)"
					class="row mb-2">
					<div class="col-auto">
						<p class="mb-0">
							<img
								:src="require(`@/assets/images/common/cc/logos/${cardLogo}.png`)"
								class="my-auto mr-1"
								style="width:45px; height:30px;">
							<span class="text-muted"> ****{{ paymentMethod.last_digits.length > 0 ? paymentMethod.last_digits : '' }}</span>
						</p>
					</div>
				</div>
			</template>
			<div
				v-else
				class="row mb-2">
				<div
					class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
					<div class="col-12">
						<div class="h2">
							<i class="fa fa-fw fa-spinner fa-pulse" />
							<h4 class="mt-3">
								{{ translate('loading') }}
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { AVAILABLECC, CREDIT_CARD_FORM_METHODS } from '@/settings/CreditCard';
import Country from '@/util/Country';
import CreditCard from '@/util/CreditCard';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import store from '../../store';
import { CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';

export default {
	name: 'PaymentOverview',
	mixins: [Addresses, CommonMix],
	props: {
		loadingOverride: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			creditCard: new CreditCard(),
			stateData: new Country(),
			billing: {
				first_name: '',
				last_name: '',
				address: '',
				address2: '',
				postcode: '',
				city: '',
				region_name: '',
				country_code: '',
			},
			paymentMethod: {
				name: '',
				card_type: '',
				last_digits: '',
			},
			CREDIT_CARD_FORM_METHODS,
		};
	},
	computed: {
		loadingState() {
			return this.stateData.data.loading;
		},
		cardLogo() {
			if (this.paymentMethod.card_type.length > 0 && AVAILABLECC.includes(this.paymentMethod.card_type)) {
				return this.paymentMethod.card_type;
			}
			return 'cc';
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
	},
	watch: {
		initialInformation() {
			if (!store.getters.getCompletedSteps().includes('TransferFeePayment')) {
				this.loadFromInitialInformation();
			}
		},
		addressLoading(newVal) {
			if (!newVal && this.hasAddressData && store.getters.getCompletedSteps().includes('TransferFeePayment')) {
				if (this.hasAddressData) {
					this.loadFromStoredPaymentStep(newVal);
				} else {
					this.$emit('noData');
				}
			}
		},
	},
	mounted() {
		if (store.getters.getCompletedSteps().includes('TransferFeePayment')) {
			this.loadFromStoredPaymentStep();
		} else {
			this.loadFromInitialInformation();
		}
	},
	methods: {
		loadFromInitialInformation() {
			if (Object.keys(this.initialInformation).length) {
				this.useInitialInformation();
			}
			return null;
		},
		loadFromStoredPaymentStep() {
			if (this.hasAddressData) {
				this.useStepInformation();
			}
			return null;
		},
		useStepInformation() {
			const stepInfo = this.getStepInformation('TransferFeePayment');
			const { billing: billingAddress, payment_method: paymentMethod } = stepInfo.payment;

			Object.keys(paymentMethod).forEach((element) => {
				this.paymentMethod[element] = paymentMethod[element];
			});
			this.paymentMethod.name = paymentMethod.name;

			if (this.paymentMethod.card_id) {
				this.creditCard.getCreditCard(this.$user.details().id, this.paymentMethod.card_id).then((response) => {
					this.paymentMethod = {
						...this.paymentMethod,
						card_type: response.attributes.card_type,
						last_digits: response.attributes.last_digits,
					};
				}).catch(() => {});
			}

			if (billingAddress.address_id) {
				this.billing.address_id = billingAddress.address_id;
				const foundAddress = this.addressData.find((address) => +address.id === +this.billing.address_id);

				if (!foundAddress) {
					this.$emit('noData');
					return null;
				}

				this.billing = {
					...this.billing,
					first_name: foundAddress.attributes.first_name,
					last_name: foundAddress.attributes.last_name,
					address: foundAddress.attributes.address,
					address2: foundAddress.attributes.address2,
					postcode: foundAddress.attributes.postcode,
					city: foundAddress.attributes.city,
					country_code: foundAddress.attributes.country.code.toLowerCase(),
				};

				this.stateData.getState(foundAddress.attributes.region.id).then((state) => {
					this.billing.region_name = state.attributes.name;
				});
			} else {
				this.useCreditCardAddress(billingAddress);
			}
			return null;
		},
		useInitialInformation() {
			if (Array.isArray(this.initialInformation.credit_card.billing_address) || !this.initialInformation.credit_card.card) {
				this.$emit('noData');
			} else {
				const cardData = this.initialInformation.credit_card.card;
				this.paymentMethod = {
					name: this.creditCardPaymentMethod,
					card_type: cardData.card_type,
					last_digits: cardData.last_digits,
				};

				const billingAddress = this.initialInformation.credit_card.billing_address;
				this.useCreditCardAddress(billingAddress);
				this.$emit('changePaymentMethodSelected', this.paymentMethod.name);
			}
		},
		useCreditCardAddress(address) {
			this.billing = {
				...this.billing,
				first_name: address.firstname,
				last_name: address.lastname,
				address: address.street[0],
				address2: address.street[1],
				postcode: address.postcode,
				city: address.city,
				country_code: address.country_id,
			};

			this.stateData.getState(address.region.region_id).then((state) => {
				this.billing.region_name = state.attributes.name;
			});
		},
	},
};
</script>
