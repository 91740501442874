<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2">
		<div
			v-if="!!Object.keys(address).length && !loadingOverride"
			class="col-auto">
			<p class="mb-0">
				<b>{{ address.first_name }} {{ address.last_name }}</b>
			</p>
			<p class="mb-0">
				{{ address.address }}
			</p>
			<p class="mb-0">
				{{ address.address2 }}
			</p>
			<p class="mb-0">
				{{ address.city }}, {{ address.region.name }}, {{ address.postcode }}
			</p>
			<p class="mb-0">
				{{ translate(address.country.code.toLowerCase()) }}
			</p>
			<div
				v-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
				class="mt-1 mb-0">
				<img
					v-if="shippingMethodInfo.image"
					:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
					class="my-auto border"
					style="width: 45px; height: 30px;">
				<p class="text-muted d-inline">
					<span
						v-if="shippingMethodInfo.name"
						class="ml-1">
						{{ translate(shippingMethodInfo.name) }}
					</span>
					<small
						v-if="deliveryDays.length"
						class="font-italic">
						<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
					</small>
				</p>
				<br>
				<small
					v-if="shippingNotice.length"
					class="font-italic"
					v-html="translate(shippingNotice)" />
			</div>
		</div>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	SHIPPING_METHOD_CODES as shippingMethods,
} from '@/settings/Shipping';
import Country from '@/util/Country';
import Addresses from '../../mixins/Addresses';
import CommonMix from '../../mixins/Common';
import store from '../../store';

export default {
	name: 'ShippingOverview',
	mixins: [Addresses, CommonMix],
	props: {
		loadingOverride: {
			type: Boolean,
			default: false,
		},
		shippingMethodImport: {
			type: String,
			default: '',
		},
		overrideShippingMethod: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			stateData: new Country(),
			addressId: 0,
			shippingMethod: {
				shipping_method: '',
			},
			shippingMethods,
		};
	},
	computed: {
		shippingMethodInfo() {
			try {
				const storedShippingMethod = this.shippingMethod.shipping_method;
				const { shippingMethodImport } = this;
				const loadedShippingMethodName = this.overrideShippingMethod ? shippingMethodImport : storedShippingMethod;

				if (loadedShippingMethodName === '') {
					return {};
				}

				const shippingMethod = { name: loadedShippingMethodName, ...this.shippingMethods[loadedShippingMethodName] };
				return shippingMethod;
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
		address() {
			const foundAddress = this.addressData.find((address) => +address.id === +this.addressId);
			return foundAddress ? foundAddress.attributes : {};
		},
	},
	watch: {
		initialInformation() {
			if (!store.getters.getCompletedSteps().includes('TransferFeeShipping')) {
				this.loadFromInitialInformation();
			}
		},
	},
	created() {
		if (store.getters.getCompletedSteps().includes('TransferFeeShipping')) {
			const stepInfo = this.getStepInformation('TransferFeeShipping');
			const { shipping } = stepInfo;
			if (typeof shipping !== 'undefined') {
				this.shippingMethod.shipping_method = shipping.shipping_method;
				this.addressId = shipping.shipping_address.address_id;
			}
		} else {
			this.loadFromInitialInformation();
		}
	},
	methods: {
		loadFromInitialInformation() {
			if (!Object.keys(this.initialInformation).length) {
				return null;
			}

			if (Array.isArray(this.initialInformation.shipping_address)) {
				this.$emit('noData');
			} else {
				this.shippingMethod.shipping_method = this.initialInformation.shipping_method;
				this.addressId = this.initialInformation.shipping_address.id;
			}
			return null;
		},
	},
};
</script>
