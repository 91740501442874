<!-- eslint-disable vue/no-v-html -->
<template>
	<cart-totals
		:class="{ 'outline': !editingOtherSteps }"
		:loading="cartLoading"
		:totals="cartTotals"
		:small-text-totals="cartSmallTextTotals"
		:cart-items="cartItems"
		:total="cartTotal"
		:total-volume="totalVolume"
		:disable-action="!disclaimer || editingOtherSteps || !dataIsComplete || !cartItems.length || loadingInitialInformation"
		:exchange="exchange"
		:discount-detail="discountDetail"
		action-classes="btn-lg"
		@action="prepareSuccess">
		<div class="row">
			<div class="col">
				<disclaimer
					:checked="disclaimer"
					:terms="termsAndPolicies"
					@change="disclaimer = $event" />
			</div>
		</div>
	</cart-totals>
</template>
<script>
import CartTotals from '@/components/Cart/CartTotals';
import {
	FORBIDDEN, NOT_FOUND, UNPROCESSABLE, INTERNAL_SERVER_ERROR,
} from '@/settings/Errors';
import { distributor, customer, affiliate } from '@/settings/Roles';
import { SPLIT_PAYMENTS, CC_PAYMENT_METHOD_BY_COUNTRY } from '@/settings/Purchase';
import Disclaimer from './Disclaimer';
import CommonMix from '../../mixins/Common';
import store from '../../store';

export default {
	name: 'OrderSummary',
	components: {
		CartTotals,
		Disclaimer,
	},
	mixins: [CommonMix],
	props: {
		cartId: {
			type: [String, Number],
			default: '',
		},
		packageCodeName: {
			type: String,
			default: '',
		},
		isPack: {
			type: Boolean,
			default: false,
		},
		cartLoading: {
			type: Boolean,
			default: false,
		},
		cartItems: {
			type: Array,
			default: () => [],
		},
		cartTotals: {
			type: Object,
			default: () => ({}),
		},
		cartSmallTextTotals: {
			type: Array,
			default: () => [],
		},
		cartTotal: {
			type: String,
			default: '',
		},
		termsAndPolicies: {
			type: Object,
			default: () => ({}),
		},
		totalVolume: {
			type: String,
			default: '',
		},
		exchange: {
			type: String,
			default: '',
		},
		showCredits: {
			type: Boolean,
			default: false,
		},
		editingOtherSteps: {
			type: Boolean,
			default: false,
		},
		upgradePackages: {
			type: Array,
			default: () => [],
		},
		upgradeVolume: {
			type: Number,
			default: 0,
		},
		hasUpgradePackages: {
			type: Boolean,
			default: false,
		},
		clickedPaymentMethod: {
			type: String,
			default: '',
		},
		discountDetail: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			alert: new this.$Alert(),
			distributor,
			customer,
			affiliate,
			disclaimer: false,
			idempotencyId: '',
			paymentMethod: {
				name: '',
			},
			splitPaymentsConfig: SPLIT_PAYMENTS,
			showFreeProductsModal: false,
			selectableProducts: {},
		};
	},
	computed: {
		usableSources() {
			const initialInfo = this.initialInformation;

			return {
				initialShippingInfo: !Array.isArray(this.initialInformation.shipping_address),
				initialPaymentInfo: !!initialInfo.credit_card.card && !Array.isArray(initialInfo.credit_card.billing_address),
				storedShippingInfo: store.state.completedSteps.includes('TransferFeeShipping'),
				storedPaymentInfo: store.state.completedSteps.includes('TransferFeePayment'),
			};
		},
		dataIsComplete() {
			return (this.usableSources.initialShippingInfo || this.usableSources.storedShippingInfo)
				&& (this.usableSources.initialPaymentInfo || this.usableSources.storedPaymentInfo);
		},
		isDistributor() {
			return this.$user.details().type === distributor;
		},
		creditCardPaymentMethod() {
			return CC_PAYMENT_METHOD_BY_COUNTRY[this.country] ?? CC_PAYMENT_METHOD_BY_COUNTRY.default;
		},
	},
	watch: {
		currentValidation() {
			this.getStartingInfo();
		},
		completedSteps() {
			this.getStartingInfo();
		},
	},
	mounted() {
		this.getStartingInfo();
	},
	methods: {
		getStartingInfo() {
			this.idempotencyId = this.createUuid();
			this.stepNames.forEach((stepName) => {
				if (stepName === 'TransferFeeConfirmation') {
					return null;
				}

				const stepInfo = this.getStepInformation(stepName);
				if (stepName === 'TransferFeePayment') {
					// eslint-disable-next-line camelcase
					this.paymentMethod.name = stepInfo.payment?.payment_method?.name ?? '';
				}

				return null;
			});
		},
		prepareSuccess() {
			this.selectableProducts = {};
			this.goSuccess();
		},
		goSuccess() {
			this.$emit('purchaseClick');

			let payload = {
				cart_id: this.cartId,
				package_code_name: this.packageCodeName,
				is_pack: this.isPack,
				shipping: {
					shipping_address: {},
				},
				payment: {
					payment_method: {},
				},
				selectable_products: this.selectableProducts,
			};

			if (this.usableSources.initialShippingInfo) {
				payload.shipping.shipping_address.address_id = this.initialInformation.shipping_address.id;
			}

			if (this.usableSources.initialPaymentInfo) {
				payload.payment.payment_method = {
					name: this.creditCardPaymentMethod,
					card_id: this.initialInformation.credit_card.card.id,
				};
			}
			const shippingStepInfo = this.getStepInformation('TransferFeeShipping');
			if (Object.keys(shippingStepInfo).length) {
				payload.shipping.shipping_address.address_id = this.getStepInformation('TransferFeeShipping').shipping.shipping_address.address_id;
			}

			const paymentStepInfo = this.getStepInformation('TransferFeePayment');
			if (Object.keys(paymentStepInfo).length) {
				const { payment_method: paymentMethod } = paymentStepInfo.payment;
				payload.payment.payment_method = paymentMethod;
			}

			this.stepNames.forEach((stepName) => {
				if (stepName !== 'TransferFeeConfirmation') {
					payload = { ...payload, ...this.getStepInformation(stepName) };
					if (stepName === 'TransferFeeShipping') {
						payload.shipping.shipping_method = '';
					} else if (stepName === 'TransferFeePayment') {
						payload.payment.payment_method.idempotency_id = this.idempotencyId;
					}
				}
			});

			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('processing_request'), this.translate('white_util_checkout'), options);
			try {
				const validation = { ...payload, step: this.getStepValidationByName('TransferFeeConfirmation') };
				this.confirmPurchase(validation).then((response) => {
					this.removeInfo().then(() => {
						// Reload user data in case this purchase reactivated their account
						this.$user.init();

						const {
							redirect_url: redirectUrl,
							verification_required: verificationRequired,
							crypto_wallet: cryptoWallet,
						} = response.response;
						// Redirect to instructed page
						if (redirectUrl) {
							window.location.href = redirectUrl;
							return;
						}
						// Pass payment method to success page in case we need to display an alert for it
						const routeOptions = {
							name: 'TransferFeeSuccess',
							query: { payment_method: payload.payment.payment_method.name ?? '' },
						};
						// Show verbal verification alert
						if (verificationRequired) {
							routeOptions.query.verification_required = true;
						}
						// Go to crypto payment details
						if (cryptoWallet) {
							routeOptions.query = { wallet_address: cryptoWallet };
						}
						// Go to new route
						this.$router.replace(routeOptions);
						// Close 'Processing request...' alert
						this.alert.close();
					});
				}).catch((error) => {
					this.idempotencyId = this.createUuid();
					if ([...NOT_FOUND, ...FORBIDDEN].includes(error.status)) {
						this.$emit('invalidRequest', error);
					}
					if (UNPROCESSABLE.includes(error.status)) {
						let isCatchableError = false;
						const {
							cart_id: cartId,
							payex,
							'shipping.shipping_address.address_id': shippingAddressId,
							'payment.billing.address_id': billingAddressId,
							'payment.payment_method.name': paymentMethodName,
							'payment.payment_method.card_id': cardId,
						} = error.errors;
						if (typeof cartId !== 'undefined') {
							isCatchableError = true;
							if (typeof cartId === 'string' || typeof cartId === 'number') {
								this.$emit('cartReplaced', cartId);
							} else {
								let response = '';
								cartId.forEach((item) => { response += `${item} \n`; });
								this.alert.toast('error', response, { timer: 6000 });
								setTimeout(() => {
									this.$emit('cartValidationError');
								}, 6000);
							}
						}
						if (typeof payex !== 'undefined') {
							isCatchableError = true;
							let response = '';
							payex.forEach((item) => { response += `${item} <br>`; });
							this.alert.error(this.translate('something_went_wrong'), response, true);
						}
						if (typeof paymentMethodName !== 'undefined') {
							isCatchableError = true;
							let response = '';
							paymentMethodName.forEach((item) => { response += `${item} \n`; });
							this.alert.toast('error', response, { timer: 6000 });
						}
						const errors = [];
						if (typeof shippingAddressId !== 'undefined') {
							shippingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof billingAddressId !== 'undefined') {
							billingAddressId.forEach((item) => { errors.push(item); });
						}
						if (typeof cardId !== 'undefined') {
							cardId.forEach((item) => { errors.push(item); });
						}
						this.$emit('purchaseErrors', errors);

						if (error.length) {
							isCatchableError = true;
							this.alert.close();
						}
						if (isCatchableError === false) {
							this.alert.toast('error', this.translate('something_went_wrong'), { timer: 6000 });
						}
					} else if (INTERNAL_SERVER_ERROR.includes(error.status)) {
						this.alert.toast('error', this.translate('something_went_wrong'), { timer: 2500 });
						setTimeout(() => { this.$emit('serverError'); }, 2500);
					}
				});
			} catch (error) {
				if (process.env.NODE_ENV !== 'production') {
					console.warn(error);
				}
			}
			return null;
		},
		selectedPaymentMethod() {
			if (this.paymentMethod.name === '') {
				return this.initialInformation.credit_card?.card ? this.creditCardPaymentMethod : '';
			}
			return this.paymentMethod.name;
		},
	},
};
</script>
<style scoped>
.outline {
	outline: 0;
	border-radius: 0.3rem;
	-webkit-box-shadow: 0 0 0 0.2rem #eb772f40;
	box-shadow: 0 0 0 0.2rem #eb772f40; /* eb772f40 */
}
</style>
